
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "tranche details view",
  components: {},
  data() {
    return {
      load: false,
      id: this.$route.params.id,
      trancheDetails: {},
    };
  },
  async created() {
    this.load = true;
    await this.getTrancheDetails();
  },
  methods: {
    async getTrancheDetails() {
      this.load = true;
      await ApiService.get("configurations/tranche/show/" + `${this.id}`)
        .then((response) => {
          this.trancheDetails = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
